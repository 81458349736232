import { action, observable, makeObservable, observe } from 'mobx';
import { parseUrlComponents } from '../../helpers/api';

export default class NavigationStore {
  /**
   * holds the routerStore
   */
  routerStore = {};

  /**
   * // To stop listening, call the function returned from listen().
   * unlisten();
   */
  unlisten = null;

  /**
   * holds current pathname
   */
  pathname = '';
  navIsActive = false;
  menuButton = null;

  constructor(routerStore) {
    makeObservable(this, {
      navIsActive: observable,
      menuButton: observable,
      pathname: observable,
      setMenuButton: action,
      toggleNav: action,
      closeNav: action,
    });

    this.ts = new Date().getTime();

    this.routerStore = routerStore;
    this.pathname = routerStore.location?.pathname;
    observe(routerStore, 'location', (change) => {
      const pathname = change.newValue.pathname;
      if (this.pathname !== pathname) {
        this.pathname = pathname;
        this.closeNav();
      }
    });
  }

  handleClick = (e, item) => {
    const urlComponents = item.url_components;

    // use routerStore only if link is internal
    // const homeUrl = this.postTypesRoutes._meta.home_url;
    const isExtern = /^http:|^https:|^\/\/|^\/content|^#|^mailto:|^tel:/.test(item.url);
    if (!isExtern) {
      let path;
      if (typeof urlComponents !== 'object' || !urlComponents.hasOwnProperty('path')) {
        path = item.url;
      } else {
        path = urlComponents.path;
        if (urlComponents.hasOwnProperty('query')) {
          path = path + '?' + urlComponents.query;
        }
        if (urlComponents.hasOwnProperty('fragment')) {
          path = path + '#' + urlComponents.fragment;
        }
      }

      e.preventDefault();
      this.routerStore.navigate(path);
      this.closeNav();
      return false;
    }
  };

  isCurrentUrl = (url) => {
    if (typeof url === 'undefined') return false;

    const hostname = window?.location.hostname;
    const pathname = this.routerStore.location.pathname;
    const urlComponents = parseUrlComponents(url);
    return pathname === urlComponents.pathname && hostname === urlComponents.hostname;
  };

  toggleNav = () => {
    this.navIsActive = !this.navIsActive;
  };

  /**
   * on location change / navigate close the menu
   */
  closeNav = () => {
    this.navIsActive = false;
  };

  setMenuButton = (element) => {
    this.menuButton = element;
  };
}
