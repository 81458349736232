import React, { useContext } from 'react';
import { inject, observer } from 'mobx-react';
import { Navigation, Virtual, FreeMode } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { renderText } from '../../../helpers/api';
import UniLink from '../../elements/UniLink/UniLink';
import { RouterContext } from '../../../stores/RouterContext';

/**
 * editorial swiper slider displays custom amount of slides
 * @see https://swiperjs.com/swiper-api
 */

const ACFSliderSM = inject('windowStore')(
  observer((props) => {
    const routerContext = useContext(RouterContext);

    const windowStore = props.windowStore;
    const sliderItems = props.content.mink_fc_slider_sm_items;
    let itemWidth = 246;
    let portal = false;
    if (routerContext.postType && routerContext.postType.startsWith('sanoa_portal')) {
      itemWidth = 300;
      portal = true;
    }
    let isSlider = false;
    if (sliderItems) {
      isSlider =
        (windowStore.isSmallerThan('sm') && 1 < sliderItems.length) ||
        (windowStore.isSmallerThan('md') && 2 < sliderItems.length) ||
        (windowStore.isGreaterEqualThan('md') && 3 < sliderItems.length);
    }

    const SliderItem = ({ item, showImages }) => {
      let linkUrl = false;
      if (item.link) {
        linkUrl = item.link.url;
      }
      return (
        <div className="-item-wrapper -shadow">
          {linkUrl && (
            <UniLink
              className="mink-slider-sm__item-link"
              href={linkUrl}
              target={item.link.target}
              title={item.title}
            />
          )}
          {showImages ? (
            <div className="-wrapper -with-img">
              <div className="aspect-content">
                <div className="-img">
                  {item.img ? (
                    <>
                      <img
                        width={portal ? item.img.sizes['small-width'] : item.img.sizes['slider-width']}
                        height={portal ? item.img.sizes['small-height'] : item.img.sizes['slider-height']}
                        src={portal ? item.img.sizes.small : item.img.sizes.slider}
                        alt={item.img.alt}
                        title={item.img.title}
                      />
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          ) : null}
          <div className={showImages ? '-text-wrapper' : '-text-wrapper -no-img'}>
            {linkUrl ? (
              <div className="-head">
                <UniLink href={linkUrl} target={item.link.target}>
                  {item.title}
                </UniLink>
              </div>
            ) : (
              <div className="-head-no-link">{item.title}</div>
            )}
            <div className="-text">{renderText(item.text)}</div>
          </div>
          {linkUrl ? (
            <div className="-link-wrapper">
              <div className="-link">
                <UniLink href={linkUrl} target={item.link.target}>
                  {item.link.title}
                </UniLink>
              </div>
            </div>
          ) : null}
        </div>
      );
    };

    if (isSlider) {
      return (
        <Swiper
          slidesOffsetBefore={5}
          slidesOffsetAfter={5}
          width={itemWidth}
          spaceBetween={16}
          freeMode
          centerInsufficientSlides={false}
          navigation={sliderItems.length <= 2 ? false : true}
          modules={[FreeMode, Navigation, Virtual]}
          className="mink-slider-sm -autoheight"
          virtual={{ enabled: true, addSlidesAfter: 5, addSlidesBefore: 5 }}
        >
          {sliderItems &&
            sliderItems.map((item, i) => {
              return '' !== item.title && '' !== item.text && item.display ? (
                <SwiperSlide virtualIndex={i} key={i + '_' + item.text} className="mink-slider-sm__item">
                  <SliderItem item={item} showImages={props.content.mink_fc_slider_sm_show_images} />
                </SwiperSlide>
              ) : null;
            })}
        </Swiper>
      );
    } else {
      return (
        <div className="mink-slider-sm mink-slider-sm-inactive -autoheight">
          {sliderItems &&
            sliderItems.map((item, i) => {
              return '' !== item.title && '' !== item.text ? (
                <div key={i + '_' + item.text} className="mink-slider-sm__item" style={{ width: itemWidth - 6 }}>
                  <SliderItem item={item} showImages={props.content.mink_fc_slider_sm_show_images} />
                </div>
              ) : null;
            })}
        </div>
      );
    }
  })
);

export default ACFSliderSM;
