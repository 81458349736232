import React from 'react';
import SubMenuModeratedItem from '../SubmenuItem/SubMenuModeratedItem';

const SubMenuModerated = (props) => {
  const items = props.items;
  const level = props.depthLevel;

  return (
    <ul className={!level ? 'main-menu list-unstyled' : 'sub-menu list-unstyled'}>
      {items.map((item) => {
        if (item) {
          return (
            <SubMenuModeratedItem
              key={'mi_' + (item.menu_item_id ? item.menu_item_id : item.title)}
              item={item}
              depthLevel={level}
              rootElement={props.rootElement}
            />
          );
        } else {
          return null;
        }
      })}
    </ul>
  );
};

export default SubMenuModerated;
