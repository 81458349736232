import React, { useContext } from 'react';
import SubMenuItem from '../../nav/SubmenuItem/SubMenuItem';
import { Link } from 'react-router-dom';
import CollapseSimple from '../../elements/Collapse/CollapseSimple';
import useEventListener from '@use-it/event-listener';
import { observer, inject } from 'mobx-react';
import { RouterContext } from '../../../stores/RouterContext';

const HeaderNav = inject('navigationStore')(
  observer((props) => {
    const navigation = props.post.navigation;
    const routerContext = useContext(RouterContext);
    const element = React.createRef();
    const locationPath = routerContext.locationPath;
    const locationSlug = routerContext.locationSlug;

    const handleDocumentClick = (e) => {
      // close menu on outside clicks
      const container = element.current;
      // check if menu button was clicked to prevent open state problems
      if (
        e.target !== container &&
        e.target !== props.navigationStore.menuButton &&
        (!props.navigationStore.menuButton || !props.navigationStore.menuButton.contains(e.target)) &&
        !container.contains(e.target)
      ) {
        props.navigationStore.closeNav();
      }
    };

    useEventListener('click', handleDocumentClick, document, { capture: true });

    let locationsMenu = navigation?.locations?.children?.length ? navigation.locations : null;

    const serviceMenu = navigation?.services?.children?.length ? navigation.services : null;
    const brandMenu = navigation?.brands?.children?.length ? navigation.brands : null;
    // indicates if location param is set, so it is not a location
    const locationLink = routerContext.location.pathname.includes(locationPath) ? true : false;
    if (serviceMenu?.children?.length) {
      serviceMenu.children.forEach((child) => {
        if (child?.children?.length) {
          child.children.forEach((item) => {
            item.url = item.url.includes(locationPath) ? item.url : locationPath + item.url;
          });
        }
      });
    }
    if (brandMenu?.children?.length) {
      brandMenu.children.forEach((child) => {
        if (child?.children?.length) {
          child.children.forEach((item) => {
            item.url = item.url.includes(locationPath) ? item.url : locationPath + item.url;
          });
        }
      });
    }

    const socialMenu = [];
    let whatsAppData = undefined;
    const socialData = navigation.social_networks;
    if (socialData) {
      const socialIDs = [
        ['facebook_link', 'Facebook'],
        ['instagram_link', 'Instagram'],
        ['linkedin_link', 'LinkedIn'],
        ['recruiting_link', 'Stellenangebote'],
        ['youtube_link', 'YouTube'],
      ];
      socialIDs.forEach((id) => {
        const entry = socialData[id[0]];
        const title = entry?.title ? entry.title : id[1];
        if (entry && title && entry.url) {
          socialMenu.push({ key: id, title: title, url: entry.url, target: '_blank' });
        }
      });
      if (socialData.whatsapp_text && socialData.whatsapp_number) {
        const phoneNumber = socialData.whatsapp_number.replace('https://wa.me/', '+');
        whatsAppData = {
          title: socialData.whatsapp_text,
          url: socialData.whatsapp_number,
          target: '_blank',
          phone: phoneNumber,
        };
      }
    }

    return (
      <CollapseSimple isOpen={props.navigationStore.navIsActive}>
        <div className="ida-header__menu" ref={element}>
          <div className="container">
            <nav>
              <ul className={'main-menu list-unstyled'}>
                <li className={'nav_item'}>
                  <Link
                    onClick={(e) => {
                      //props.navigationStore.closeNav();
                    }}
                    className="main-menu-link"
                    to={'/'}
                  >
                    Homepage
                  </Link>
                </li>
                {locationSlug && locationLink && (
                  <li className={'nav_item'}>
                    <Link
                      onClick={(e) => {
                        //props.navigationStore.closeNav();
                      }}
                      className="main-menu-link"
                      to={locationPath + '/'}
                    >
                      Standortinformationen
                    </Link>
                  </li>
                )}
                {locationsMenu?.children?.length && (
                  <SubMenuItem key="locations" item={locationsMenu} depthLevel={0} rootElement={element} />
                )}
                {serviceMenu?.children?.length && (
                  <SubMenuItem key="services" item={serviceMenu} depthLevel={0} rootElement={element} />
                )}
                {brandMenu?.children?.length && (
                  <SubMenuItem key="brands" item={brandMenu} depthLevel={0} rootElement={element} />
                )}
                {socialMenu.map((item) => {
                  return <SubMenuItem key={item.key} item={item} depthLevel={0} rootElement={element} />;
                })}
                {whatsAppData && (
                  <SubMenuItem key={'whatsapp'} item={whatsAppData} depthLevel={0} rootElement={element} />
                )}
                {/*locationsMenu?.children?.length && (
                  <SubMenuItem
                    key="locations"
                    item={locationsMenu}
                    depthLevel={0}
                    rootElement={element}
                  />
                )}
                {props.navigationStore.footerNavigationItems.map((item) => {
                  return (
                    <SubMenuItem
                      key={'mi' + item.menu_item_id}
                      item={item}
                      depthLevel={0}
                      rootElement={element}
                    />
                  );
                })*/}
              </ul>
            </nav>
          </div>
        </div>
      </CollapseSimple>
    );
  })
);

export default HeaderNav;
